import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

import 'react-quill/dist/quill.snow.css';
import SettingsIcon from '@mui/icons-material/Settings';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    IconButton,
    MenuItem,
    Menu
} from '@mui/material';


function Documents() {
    const [authData, setAuthData] = useState(null);
    const [documents, setDocuments] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const eid = localStorage.getItem('default');
        const type = "rackspace_auth";

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const token = data.data.response.rackspace.token;
                    const storageUrl = data.data.response.rackspace.storageUrl;

                    localStorage.setItem('rackspace_token', token);
                    localStorage.setItem('rackspace_storage_url', storageUrl);

                    setAuthData({ token, storageUrl });
                }
            })
            .catch(error => console.error('Error during authentication:', error));
    }, []);

    useEffect(() => {
        const eid = localStorage.getItem('default');
        const nonce = localStorage.getItem("nonce")
        const type = "document";

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&nonce=' + nonce;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Transform the response data to match the format expected by the table
                    const formattedDocuments = data.data.response.map(item => ({
                        id: item.id,
                        name: item.document.name,
                        last_modified: item.document.created_date,
                        bytes: parseInt(item.document.size, 10), // Ensure size is an integer
                    }));
                    setDocuments(formattedDocuments);
                } else {
                    setDocuments([]);
                }
            })
            .finally(() => setLoading(false));
    }, []);


    // download the file function -- fetch to rackspace
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event, doc) => {
        setAnchorEl(event.currentTarget);
        setSelectedDoc(doc);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedDoc(null);
    };

    const handleDownload = (fileName) => {
        const eid = localStorage.getItem('default');
        const type = "rackspace_download_document";
        const token = localStorage.getItem('rackspace_token');
        const storageUrl = localStorage.getItem('rackspace_storage_url');

        if (!eid || !token || !storageUrl) {
            console.error("Required parameters missing for the request.");
            return;
        }

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&token=' + token + '&storage_url=' + encodeURIComponent(storageUrl) + '&filename=' + fileName;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success && data.data?.response?.file) {
                    const { file, filename, content_type } = data.data.response;
                    downloadFile(file, filename, content_type);
                } else {
                    console.error("Failed to download file", data);
                }
            })
            .catch(err => {
                console.error("Error while fetching the document", err);
            });
    };

    function downloadFile(base64Data, fileName, contentType = 'application/octet-stream') {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
    }


    // ======||====== This is to get the documents directly from Rackspace ======||=====  

    // useEffect(() => {
    //     if (authData) {
    //         const eid = localStorage.getItem('default');
    //         const type = "rackspace_documents";
    //         const token = localStorage.getItem('rackspace_token');
    //         const storageUrl = localStorage.getItem('rackspace_storage_url');

    //         if (!eid || !token || !storageUrl) return;

    //         function protocolpluspath() {
    //             return window.location.protocol + '//' + window.location.host + '/';
    //         }

    //         const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&token=' + token + '&storage_url=' + encodeURIComponent(storageUrl);

    //         fetch(url)
    //             .then(response => response.json())
    //             .then(data => {
    //                 if (data.success) {
    //                     // setDocuments(data.data.response);
    //                 }
    //             })
    //     }
    // }, [authData]);


    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold mb-6">DOCUMENTS</h1>
            </div>

            <div className="p-4">
                {loading && (
                    <div className="flex justify-center items-center h-64">
                        <CircularProgress />
                    </div>
                )}
                {!loading && documents.length > 0 && (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Settings</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Size</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documents.map((doc, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <IconButton onClick={(event) => handleMenuOpen(event, doc)}>
                                                <SettingsIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>{doc.name}</TableCell>
                                        <TableCell>
                                            {doc.last_modified === "0000-00-00 00:00:00"
                                                ? "Not available"
                                                : new Date(doc.last_modified).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {(doc.bytes / 1024 / 1024).toFixed(2)} MB
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {!loading && documents.length === 0 && (
                    <div className="text-center">No documents found.</div>
                )}

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => {
                        if (selectedDoc) handleDownload(selectedDoc.name);
                        handleMenuClose();
                    }}>
                        Download
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}


export default Documents;