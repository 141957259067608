import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, Typography } from '@mui/material';
import { SavedIcon, CheckIcon, FullViewIcon, SaveIcon } from '../../assets/icons/hiking';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export default function Testing() {


    const backendUrl = "https://1849-199-59-112-229.ngrok-free.app";

    const [authToken, setAuthToken] = useState(null);
    const [file, setFile] = useState(null);
    const [filesList, setFilesList] = useState([]);

    const username = "streamlineweb";
    const apiKey = "c24e42c500978995663289cdd9580566";
    const containerName = "NTT2";
    const region = "cf1";
    const storageUrl = `https://storage101.${region}.clouddrive.com/v1/MossoCloudFS_${containerName}`;
    const cdnUrl = "https://5c1f4e5c15aa5783fb58-b595a4a4f1cfbefe2a86378273c23db4.r16.cf1.rackcdn.com";


    const authenticate = async () => {
        try {
            const response = await axios.post(`${backendUrl}/authenticate`);
            setAuthToken(response.data.token);
            alert("Authentication successful!");
        } catch (error) {
            console.error("Authentication failed:", error);
            alert("Authentication failed. Check the console for details.");
        }
    };

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(file);
        });
    };

    // Fetch list of files
    const fetchFiles = async () => {
        try {
            const response = await axios.get(`${backendUrl}/files`, {
                params: { token: authToken },
            });
            setFilesList(response.data);
            alert("Files fetched successfully!");
        } catch (error) {
            console.error("Fetching files failed:", error);
            alert("Fetching files failed. Check the console for details.");
        }
    };


    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "collection_items";
        const id = localStorage.getItem("nonce");

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }
        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&id=" + id;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data)
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);


    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "collection_items";
        const id = localStorage.getItem("nonce");
        const collection_nonce = "collection_nonce_123"

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }
        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&id=" + id + "&collection_nonce=" + collection_nonce;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data)
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);



    function simulatePriceFetch() {

        const eid = localStorage.getItem('default');
        const a = localStorage.getItem("nonce");

        const payload = {
            eid: eid,
            endpoint: "v1/collection/delete/items",
            a: a,
            products: ['UEFORyBUZXN0IFByb2R1Y3Q2NzJkYjFhZDFkOGUy', 'R2VvcmdlIERlbW8gUHJvZHVjdDY3NjBiN2IwNjczYzQ=']
        };
        // 

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success === 1 && data.response && data.response.data) {
                    console.log(data)
                }
            })
            .catch(() => {
                alert("Something went wrong");
            });
    }




    function submitText() {
        const eid = localStorage.getItem('default');

        const payload = {
            eid: eid,
            endpoint: "v1/taxonomy/add",


            "taxonomy": [
                {
                    "category_nonce": "Y2F0ZWdvcnkgdGVzdGluZzY3NTQ3MDgyOWFiZjY=",
                    "tags": [
                        { "nonce": "dGFnIDIzOTgzOTI2NzU0NzA4MjliN2Ji" },
                    ]
                },
                {
                    "category_nonce": "c2VydmljZSB0b29scyBhbmQgZXF1aXBtZW50Njc1NGY5YTgxYTJhOA==",
                    "tags": [
                        { "nonce": "QnJ1c2hlczY3NTRmOWE4MWIxNjI=" },
                        { "nonce": "V29ya3Nob3AgRXF1aXBtZW50Njc1NGY5YTgxYjYyOQ==" }
                    ]
                }
            ],

            asin: "",
            base_unit: "",
            catalog_1: "Root",
            catalog_2: "Demo 1",
            catalog_3: "Demo 2",
            catalog_4: "Ascot",
            catalog_5: "BDM",
            catalog_6: "Continental",
            catalog_7: "Oliver",
            category: "",
            created_date: "",
            dimensions_unit: "",
            eid: "MTY1YjI2YzBkMGI1YTc=",
            endpoint: "v1/product/add",
            gtin: "",
            height: "",
            images_identifiers: "",
            images_primary: "",
            item_number: "",
            length: "",
            long_description: "",
            msrp: "",
            nonce: "",
            password: "tech-staff",
            price: "",
            published_date: "",
            published_status: "Published",
            sale_price: "",
            short_description: "testing",
            sku: "",
            slug: "testing",
            tags: "",
            title: "luis-testing-product",
            upc: "",
            username: "tech-staff",
            visibility: "Visible",
            weight_unit: "",
            weight_value: "",
            width: "",

        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response === "OK") {
                    alert("text updated successfully!")
                } else {
                    alert("something went wrong");
                }
            })
            .catch(() => {
                alert("something went wrong");
            });
    };



    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);



    // Handler to add a new category block
    const addCategoryBlock = () => {
        setCategoriesData(prev => [
            ...prev,
            { categoryNonce: '', tags: [''], availableTags: [] }
        ]);
    };

    // Handler for category selection change
    const handleCategoryChange = (index, newNonce) => {
        // Update the categoryNonce for the given block
        const updated = [...categoriesData];
        updated[index].categoryNonce = newNonce;
        updated[index].tags = ['']; // reset tags since category changed
        updated[index].availableTags = [];
        setCategoriesData(updated);

        // Fetch tags for that category
        if (newNonce) {
            const eid = localStorage.getItem('default');
            const type = "tags";

            function protocolpluspath() {
                return window.location.protocol + '//' + window.location.host + '/';
            }

            const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&a=' + encodeURIComponent(newNonce);

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.success && data.data && data.data.response) {
                        const updated2 = [...categoriesData];
                        updated2[index].availableTags = data.data.response; // store the fetched tags
                        setCategoriesData(updated2);
                    } else {
                        // no tags or error, handle gracefully
                        const updated2 = [...categoriesData];
                        updated2[index].availableTags = [];
                        setCategoriesData(updated2);
                    }
                })
                .catch(error => console.error('Error:', error));
        }
    };


    const addTagField = (catIndex) => {
        const updated = [...categoriesData];
        updated[catIndex].tags.push('');
        setCategoriesData(updated);
    };


    const handleTagChange = (catIndex, tagIndex, newValue) => {
        const updated = [...categoriesData];
        updated[catIndex].tags[tagIndex] = newValue;
        setCategoriesData(updated);
    };

    // Initial add of one category block by default
    useEffect(() => {
        // If you want to start with one category block already present:
        if (categoriesData.length === 0) {
            addCategoryBlock();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would gather all your form data and send it to your API
        const productData = {
            title,
            slug,
            shortDescription,
            description,
            taxonomy: categoriesData.map(cat => ({
                categoryNonce: cat.categoryNonce,
                tags: cat.tags
            }))
        };
        console.log(productData);
        // fetch(...post productData...)
    };




    return (
        <div
            style={{ paddingTop: '150px' }}>
            <Box component="form" sx={{ p: 4, backgroundColor: '#f9f9f9', borderRadius: 2 }}>

                <div>
                    <h1>Rackspace File Manager</h1>
                    {/* <button onClick={authenticate}>Authenticate</button>
                     */}
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            authenticate();
                        }}
                    >
                        Authenticate
                    </button>

                    <div>
                        <input
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                        <button
                            onClick={(e) => {
                                simulatePriceFetch();
                            }}
                        >
                            Upload File
                        </button>
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            fetchFiles();
                        }}
                        disabled={!authToken}
                    >
                        Fetch Files
                    </button>
                    <ul>
                        {filesList.map((file) => (
                            <li key={file.name}>
                                <a
                                    href={`${cdnUrl}/${file.name}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {file.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>


                {filesList.map((file) => (
                    <li key={file.name}>
                        <a
                            href={`${cdnUrl}/${file.name}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {file.name}
                        </a>
                    </li>
                ))}


            </Box>



            {/* <button onClick={submitText} className='pt-20'>
                create categories and tags!!!! -- testing now for create proudct(taxonomy)
            </button> */}


            <button
                onClick={simulatePriceFetch}
            >
                CREATE A NEW collection name
            </button>

            <form onSubmit={handleSubmit}>
                <div>
                    <label>Title:</label>
                    <input type="text" value={title} onChange={e => setTitle(e.target.value)} />
                </div>

                <div>
                    <label>Slug:</label>
                    <input type="text" value={slug} onChange={e => setSlug(e.target.value)} />
                </div>

                <div>
                    <label>Short Description:</label>
                    <textarea value={shortDescription} onChange={e => setShortDescription(e.target.value)} />
                </div>

                <div>
                    <label>Description:</label>
                    <textarea value={description} onChange={e => setDescription(e.target.value)} />
                </div>

                <hr />

                <h3>Taxonomy</h3>

                {categoriesData.map((catBlock, catIndex) => (
                    <div key={catIndex} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
                        <div>
                            <label>Select Category:</label>
                            <select
                                value={catBlock.categoryNonce}
                                onChange={(e) => handleCategoryChange(catIndex, e.target.value)}
                            >
                                <option value="">--Select Category--</option>
                                {categories.map((cat) => (
                                    <option key={cat.nonce} value={cat.nonce}>{cat.title}</option>
                                ))}
                            </select>
                        </div>

                        {catBlock.categoryNonce && (
                            <div style={{ marginTop: '10px' }}>
                                <h4>Tags for this Category</h4>
                                {catBlock.tags.map((tagValue, tagIndex) => (
                                    <div key={tagIndex}>
                                        <select
                                            value={tagValue}
                                            onChange={(e) => handleTagChange(catIndex, tagIndex, e.target.value)}
                                        >
                                            <option value="">--Select Tag--</option>
                                            {catBlock.availableTags.map((tag) => (
                                                <option key={tag.nonce} value={tag.nonce}>{tag.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                ))}
                                <button type="button" onClick={() => addTagField(catIndex)}>Add Tag</button>
                            </div>
                        )}
                    </div>
                ))}

                <button type="button" onClick={addCategoryBlock}>Add Another Category</button>

                <hr />

                <button type="submit">Save Product</button>
            </form>





            {/* <form onSubmit={handleSubmit}>
                {Object.keys(formData).map((field) => (
                    <div key={field}>
                        <label htmlFor={field}>{field.replace(/_/g, ' ')}</label>
                        <input
                            type="text"
                            id={field}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                            placeholder={field}
                        />
                    </div>
                ))}
                <button type="submit">Submit</button>
            </form> */}



            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mr-5 w-full">
                {Array(10).fill().map((_, index) => (
                    <div key={index} style={{ textAlign: 'center', position: 'relative', width: '264px', margin: 'auto' }}>
                        <div className="blur-on-hover relative" style={{ backgroundColor: 'rgba(255, 255, 255)', width: '264px', height: '307px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                            <img src={`productImage${index + 1}.jpg`} alt={`Producto ${index + 1}`} style={{ maxWidth: '220px' }} />
                            <div style={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px', boxSizing: 'border-box' }}>
                                <div style={{ borderTop: '1px solid #ccc', width: '100%', display: 'flex', justifyContent: 'space-between', position: 'relative', paddingTop: '5px' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>100-20-3429</span>
                                    <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>item 200</span>
                                </div>
                            </div>
                            <button className="add-to-cart-button font-semibold opacity-0 text-sm" style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                                Add to Cart
                            </button>
                            <button className="add-to-cart-button font-semibold opacity-0 text-sm" style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }} onClick={() => window.location.href = `/app/product?sku=SKU-00${index + 1}`}>
                                View Product
                            </button>
                        </div>

                        <div style={{ textAlign: 'left', marginTop: '10px', width: '264px', margin: 'auto' }}>
                            <span className="font-semibold text-sm block truncate">Product Title</span>
                            <span className="font-medium text-sm block truncate">Short Description</span>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ fontWeight: '700', fontSize: '20px' }}>€200.00</span>
                                <div className="cursor-pointer" style={{ position: 'relative' }}>
                                    <SaveIcon />
                                    {/* {
                                        savedNotificationProductId === product.id && (
                                            <div style={{ position: 'absolute', top: '-30px', right: '0px', backgroundColor: 'black', color: 'white', padding: '2px 4px', borderRadius: '5px', zIndex: '50', fontSize: '12px' }}>
                                                Saved
                                            </div>
                                        )
                                    } */}
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>

        </div>
    );
}
