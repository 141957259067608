import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Backdrop, CircularProgress } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { Skeleton } from '@mui/material';

function Invoice() {

    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeFilter, setActiveFilter] = useState("all");

    useEffect(() => {
        const eid = localStorage.getItem('default');
        const type = "invoice";
        const customer_number = localStorage.getItem('business_partner_id');

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&customer_number=' + customer_number;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    const listResponse =
                        data?.data?.response?.data?.envelope?.['S:Body']?.ListResponse
                            ?.ListResponse;

                    const invoiceArray = listResponse?.DataArea?.SalesInvoice_BDE ?? [];
                    setInvoices(invoiceArray);
                    setFilteredInvoices(invoiceArray);
                }
            })
            .finally(() => setLoading(false));
    }, []);



    // Search input handler
    const searchInvoice = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        applyFilters(value, activeFilter);
    };

    // Filtering logic (all,paid,partial,open) buttons
    const applyFilters = (searchValue, filter) => {
        let filtered = invoices;

        if (searchValue) {
            filtered = filtered.filter((invoice) => {
                // Convert to strings just to be safe
                const invoiceId = String(invoice.invoiceId ?? '').toLowerCase();
                const orderId = String(invoice.orderId ?? '');
                return (
                    invoiceId.includes(searchValue.toLowerCase()) ||
                    orderId.includes(searchValue)
                );
            });
        }

        filtered = filtered.filter((invoice) => {
            const numericBalance =
                parseFloat(invoice.balanceAmountInInvoiceCurrValue) || 0;
            const numericTotal =
                parseFloat(invoice.invoiceAmountInInvoiceCurrencyValue) || 0;

            if (filter === 'all') {
                return true;
            } else if (filter === 'paid') {
                return numericBalance === 0;
            } else if (filter === 'partial') {
                return numericBalance > 0 && numericBalance < numericTotal;
            } else if (filter === 'open') {
                return numericTotal > 0 && numericBalance === numericTotal;
            }
            return true;
        });

        setFilteredInvoices(filtered);
    };

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
        applyFilters(searchTerm, filter);
    };


    // https://one-api.ntt.tools/v1/express/init?id=24c4ce16872f4039a0cb221e908b0503&total_amount={balanceAmountInInvoiceCurrValue}&order_id={orderId}
    return (
        <div>

            {loading ? (
                Array.from(new Array(3)).map((_, index) => (
                    <div key={index} className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                        <Skeleton variant="text" width="60%" height={30} />
                        <Skeleton variant="text" width="80%" height={20} />
                        <Skeleton variant="text" width="50%" height={20} />
                        <div className="mt-4">
                            <Skeleton variant="rectangular" width="100%" height={100} />
                        </div>
                    </div>
                ))
            ) : (
                <div>
                    {/* Search + Filter Buttons */}
                    <div className="flex flex-col space-y-2">
                        <div className="flex items-center justify-start space-x-2">
                            <input
                                type="text"
                                className="px-4 py-2 border-gray-400 placeholder-gray-600 text-gray-600 rounded border focus:border-gray-400 focus:ring-0 cursor-text flex-grow"
                                placeholder="Search an Entry"
                                style={{ border: 'none' }}
                                value={searchTerm}
                                onChange={searchInvoice}
                            />

                            <button
                                className={`rounded-full text-sm px-6 py-3 focus:outline-none ${activeFilter === 'all' ? 'bg-gray-400' : 'bg-gray-200'
                                    } hover:bg-gray-300`}
                                onClick={() => handleFilterClick('all')}
                            >
                                All
                            </button>

                            <button
                                className={`rounded-full text-sm px-6 py-3 focus:outline-none ${activeFilter === 'open' ? 'bg-gray-400' : 'bg-gray-200'
                                    } hover:bg-gray-300`}
                                onClick={() => handleFilterClick('open')}
                            >
                                Open
                            </button>


                            <button
                                className={`rounded-full text-sm px-6 py-3 focus:outline-none ${activeFilter === 'paid' ? 'bg-gray-400' : 'bg-gray-200'
                                    } hover:bg-gray-300`}
                                onClick={() => handleFilterClick('paid')}
                            >
                                Paid
                            </button>

                            <button
                                className={`rounded-full text-sm px-6 py-3 focus:outline-none ${activeFilter === 'partial' ? 'bg-gray-400' : 'bg-gray-200'
                                    } hover:bg-gray-300`}
                                onClick={() => handleFilterClick('partial')}
                            >
                                Partial
                            </button>

                        </div>
                    </div>

                    <hr className="border-gray-300 w-full my-4" />

                    {/* Invoices List */}
                    {filteredInvoices.map((invoice) => {
                        const numericBalance = parseFloat(invoice.balanceAmountInInvoiceCurrValue) || 0;
                        const numericTotal = parseFloat(invoice.invoiceAmountInInvoiceCurrencyValue) || 0;


                        const handlePayNow = () => {
                            const url = `https://one-api.ntt.tools/v1/express/init?id=24c4ce16872f4039a0cb221e908b0503&total_amount=${encodeURIComponent(invoice.balanceAmountInInvoiceCurrValue)}&order_id=${encodeURIComponent(invoice.orderId)}`;
                            window.location.href = url;
                        };

                        return (
                            <div key={invoice.invoiceId}>
                                <div className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                                    <div className="flex justify-between items-center mb-4">
                                        <div className="flex flex-col space-y-2">
                                            <div className="flex items-center justify-between gap-x-4">
                                                <p className="text-lg font-bold text-black">
                                                    Invoice No: {invoice.invoiceId}
                                                </p>
                                                <span
                                                    className={`px-3 py-1 text-white rounded-full text-xs ${numericBalance === 0
                                                        ? 'bg-green-500'
                                                        : numericBalance < numericTotal
                                                            ? 'bg-yellow-500'
                                                            : 'bg-red-500'
                                                        }`}
                                                >
                                                    {numericBalance === 0
                                                        ? 'Paid'
                                                        : numericBalance < numericTotal
                                                            ? 'Partial'
                                                            : 'Open'}
                                                </span>
                                            </div>
                                            <p className="text-sm text-black">
                                                Order Number: {invoice.orderId}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                Invoice Date: {invoice.invoiceDate} | Due Date:{' '}
                                                {invoice.dueDate}
                                            </p>
                                        </div>

                                        <div className="space-x-2">
                                            {numericBalance > 0 && numericBalance >= numericTotal && (
                                                <button onClick={handlePayNow} className="px-4 py-2 text-sm bg-yellow-500 text-white rounded">
                                                    Pay Now
                                                </button>
                                            )}
                                            <button className="px-4 py-2 text-sm bg-black text-white rounded">
                                                View Order
                                            </button>
                                            <button className="px-4 py-2 text-sm bg-gray-300 rounded">
                                                More Details
                                            </button>
                                        </div>
                                    </div>

                                    <hr className="border-gray-200 my-4" />

                                    <div className="text-right space-y-4">
                                        <div>
                                            <p className="text-sm text-gray-500">Invoice Amount:</p>
                                            <p className="text-sm">
                                                {invoice.invoiceAmountInInvoiceCurrencyValue}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-sm text-gray-500">Invoice Terms:</p>
                                            <p className="text-sm">{invoice.termsOfPaymentDescription}</p>
                                        </div>
                                        <div>
                                            <p className="text-sm text-gray-500">Invoice Balance:</p>
                                            <p className="text-sm">
                                                {invoice.balanceAmountInInvoiceCurrValue}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div >


    );
}


export default Invoice;