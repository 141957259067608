import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.snow.css';
import SettingsIcon from '@mui/icons-material/Settings';

import {
    Snackbar,
    Alert
} from '@mui/material';


function UploadDocument() {

    const [buttonText, setButtonText] = useState("Select Document");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");


    useEffect(() => {
        const eid = localStorage.getItem('default');
        const type = "rackspace_auth";

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const token = data.data.response.rackspace.token;
                    const storageUrl = data.data.response.rackspace.storageUrl;

                    localStorage.setItem('rackspace_token', token);
                    localStorage.setItem('rackspace_storage_url', storageUrl);

                    console.log('Authentication successful:', data);
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setButtonText('Upload Document');
        } else {
            setSelectedFile(null);
            setButtonText('Select Document');
        }
    };


    const uploadDocumentToRackspace = () => {
        const token = localStorage.getItem('rackspace_token');
        const storageUrl = localStorage.getItem('rackspace_storage_url');

        if (!token || !storageUrl || !selectedFile) {
            alert('Missing required data. Ensure you are authenticated and select a file.');
            return;
        }

        const eid = localStorage.getItem('default');

        const reader = new FileReader();
        reader.onload = function () {
            const fileData = reader.result;

            const payload = {
                token,
                storage_url: storageUrl,
                fileName: selectedFile.name,
                file: fileData,
                endpoint: 'v1/rackspace/document/upload',
                eid,
                error: "0",
            };

            catalogs.forEach((catalog, index) => {
                if (catalog && catalog !== "Select") {
                    payload[`catalog_${index + 1}`] = catalog
                }
            })

            fetch('/admin/postData.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then(response => response.json())
                .then(result => {
                    if (result.success === 1 && result.response?.response?.nonce) {
                        setSnackbarMessage('File uploaded successfully.');
                        setSnackbarSeverity('success');
                    } else {
                        setSnackbarMessage('Document upload failed');
                        setSnackbarSeverity('error');
                    }
                    setSnackbarOpen(true);
                });
        };

        reader.readAsDataURL(selectedFile);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const removeSelectedFile = () => {
        setSelectedFile(null);
        setButtonText('Select Document');
    };

    //catalog select logic
    const [options, setOptions] = useState([]);
    const [catalogs, setCatalogs] = useState(['']);

    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "catalogs_enum";

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }

        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success && data.data && data.data.response) {
                    setOptions(data.data.response);
                } else {
                    console.error('Unexpected response format:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleCatalogChange = (index, value) => {
        const updatedCatalogs = [...catalogs];
        updatedCatalogs[index] = value;
        setCatalogs(updatedCatalogs);
    };

    const addCatalog = () => {
        setCatalogs([...catalogs, '']);
    };

    return (
        <div className="flex flex-col items-start">
            <h1 className="text-2xl font-bold mb-4">DOCUMENTS</h1>
            <div className="flex items-center mb-4">
                <button
                    className={`px-4 py-2 rounded text-white ${selectedFile ? 'bg-green-500' : 'bg-blue-500'}`}
                    onClick={() => {
                        if (buttonText === "Select Document") {
                            document.getElementById('fileInput').click();
                        } else {
                            uploadDocumentToRackspace();
                        }
                    }}
                >
                    {buttonText}
                </button>
                {selectedFile && (
                    <div className="flex items-center ml-4">
                        <span className="text-gray-700 mr-2">{selectedFile.name}</span>
                        <button
                            className="text-red-500 font-bold"
                            onClick={removeSelectedFile}
                        >
                            X
                        </button>
                    </div>
                )}
            </div>
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />

            <div>
                {catalogs.map((catalog, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <label>Catalog {index + 1}</label>
                        <select
                            value={catalog}
                            onChange={(e) => handleCatalogChange(index, e.target.value)}
                        >
                            {options.map((option, idx) => (
                                <option key={idx} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                ))}
                <span
                    onClick={addCatalog}
                    style={{
                        color: '#3b82f6',
                        cursor: 'pointer',
                        display: 'inline-block',
                        padding: '8px 16px',
                        transition: 'color 0.2s',
                    }}
                    className="hover:text-blue-600 mt-4"
                >
                    Add Catalog
                </span>

            </div>


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );

}


export default UploadDocument;